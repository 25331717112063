import React from 'react';
import i18nOptions from '__config/i18n';

// NOTE: This file is changed by the `yarn creator` command.
// Do not remove any of the !IMPORTANT! comments

// !IMPORTANT! - Importing contexts
import { NextIntlProvider, IntlErrorCode } from 'next-intl';
import { BotMakerProvider } from 'providers/botmaker';
import { AnalyticsProvider } from 'providers/analytics';
import { CarBuilderProvider } from 'providers/car-builder';
import { UserProvider } from '@auth0/nextjs-auth0';
import { UserCustomerProvider } from 'providers/user-customer';
import { ServiceAppointmentProvider } from 'providers/service-appointment';
// !IMPORTANT! - End of importing contexts // do not remove this comment

function withContext(Provider, providerData, Component) {
  return <Provider {...providerData}>{Component}</Provider>;
}

function applyContexts(comp, data) {
  /* eslint-disable max-len */
  // !IMPORTANT! - Using the list of contexts. The order of them matter here.
  comp = withContext(AnalyticsProvider, {}, comp);
  comp = withContext(CarBuilderProvider, {}, comp);
  comp = withContext(
    BotMakerProvider,
    {
      locale: data?.pageProps?.locale || data?.pageProps?.tenant?.locale || i18nOptions?.defaultLocale
    },
    comp
  );
  // !IMPORTANT! - End of using contexts // do not remove this comment
  /* eslint-enable max-len */

  //auth0
  comp = withContext(UserProvider, {}, comp);

  comp = withContext(UserCustomerProvider, {}, comp);

  comp = withContext(ServiceAppointmentProvider, {}, comp);

  // let's add i18n as the last one, so other contexts can use it too
  comp = withContext(
    NextIntlProvider,
    {
      onError(error) {
        // will be treated by the callback
      },

      getMessageFallback({ namespace, key, error }) {
        const path = [namespace, key].filter((part) => part != null).join('.');

        if (error.code === IntlErrorCode.MISSING_MESSAGE) {
          console.info(`[i18n] :: ${path} is not yet translated`);
          return i18nOptions.defaultFallback === '*' ? key : i18nOptions.defaultFallback || '';
        } else {
          console.error(`[i18n] :: Error with "${path}", please check it's format.`, error);
          return `[i18n] :: Error with "${path}"`;
        }
      },
      now: new Date(),
      locale: data?.pageProps?.messages
        ? data?.pageProps.locale || undefined // undefined will use default locale
        : i18nOptions.defaultLocale,
      messages: data?.pageProps?.messages,
      ...i18nOptions
    },
    comp
  );

  return comp;
}

export { applyContexts };
