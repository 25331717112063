import React, { createContext, useContext, useReducer } from 'react';

const defaultSteps = [
  {
    type: 'VEHICLE',
    name: 'Vehículo',
    description: 'Seleccioná tu vehículo',
    disabled: false,
    order: 0
  },
  {
    type: 'SERVICES',
    name: 'Servicios',
    description: 'Seleccioná el servicio que querés agendar',
    disabled: true,
    order: 1
  },
  {
    type: 'DEALERSHIP',
    name: 'Concesionario',
    description: 'Seleccioná el concesionario en el que querés agendar un turno.',
    disabled: true,
    order: 2
  },
  {
    type: 'PRICE_DATE',
    name: 'Precio y Fecha',
    description: 'Seleccioná el día y hora para la realización del turno.',
    disabled: true,
    order: 3
  },
  {
    type: 'ATTENDEE',
    name: 'Persona que asistirá',
    description: 'Informá la persona que va a asistir al turno agendado.',
    disabled: true,
    order: 4
  }
];

// Define the types of actions that can be dispatched
export const ACTIONS = {
  INITIALIZE_STEPS: 'INITIALIZE_STEPS',
  INITIALIZE_LIST_STEPS: 'INITIALIZE_LIST_STEPS',
  RESET_STATE: 'RESET_STATE',
  SET_ADDITIONAL_SERVICES: 'SET_ADDITIONAL_SERVICES',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_CUSTOMER_VEHICLE_ID: 'SET_CUSTOMER_VEHICLE_ID',
  SET_FINAL_STEP_COMPLETED: 'SET_FINAL_STEP_COMPLETED',
  SET_HAS_RECALL: 'SET_HAS_RECALL',
  SET_HAS_WASH: 'SET_HAS_WASH',
  SET_KM: 'SET_KM',
  SET_MAKE_APPOINTMENT_COMPLETED: 'SET_MAKE_APPOINTMENT_COMPLETED',
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  SET_OTHERS_SELECTED: 'SET_OTHERS_SELECTED',
  SET_SELECTED_DEALER_ID: 'SET_SELECTED_DEALER_ID',
  SET_SELECTED_SLOT: 'SET_SELECTED_SLOT',
  SET_SELECTED_VEHICLE_INFO: 'SET_SELECTED_VEHICLE_INFO',
  SET_SELECTED_VEHICLE_NAME: 'SET_SELECTED_VEHICLE_NAME',
  SET_SELECTED_WAIT_FOR_VEHICLE: 'SET_SELECTED_WAIT_FOR_VEHICLE',
  SET_SLOTS: 'SET_SLOTS',
  SET_USER_DATA_CUSTOMER: 'SET_USER_DATA_CUSTOMER',
  UPDATE_LIST_STEP: 'UPDATE_LIST_STEP'
};

const initialState = {
  currentStep: 0,
  listSteps: [],
  dealerId: null,
  customerVehicleId: null,
  selectedVehicleInfo: null,
  selectedVehicleName: null,
  hasRecall: 'true',
  hasWash: false,
  additionalServices: [],
  others: '',
  km: null,
  slots: [],
  selectedSlot: null,
  selectedWaitForVehicle: 'true',
  notification: null,
  isFinalStepCompleted: false,
  isMakeAppointmentCompleted: false
};

// Create the service appointment context
const ServiceAppointmentContext = createContext();

// Define the reducer to handle actions and update the state
const serviceAppointmentReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_CURRENT_STEP:
      return { ...state, currentStep: action.payload, selectedVehicleName: null };
    case ACTIONS.SET_SELECTED_DEALER_ID:
      return { ...state, dealerId: action.payload };
    case ACTIONS.SET_CUSTOMER_VEHICLE_ID:
      return { ...state, customerVehicleId: action.payload };
    case ACTIONS.SET_SELECTED_VEHICLE_INFO:
      return { ...state, selectedVehicleInfo: action.payload };
    case ACTIONS.SET_SELECTED_VEHICLE_NAME:
      return { ...state, selectedVehicleName: action.payload };
    case ACTIONS.SET_KM:
      return { ...state, km: action.payload };
    case ACTIONS.SET_HAS_RECALL:
      return { ...state, hasRecall: action.payload };
    case ACTIONS.SET_HAS_WASH:
      return { ...state, hasWash: action.payload };
    case ACTIONS.SET_ADDITIONAL_SERVICES:
      return { ...state, additionalServices: action.payload };
    case ACTIONS.SET_OTHERS_SELECTED:
      return { ...state, others: action.payload };
    case ACTIONS.UPDATE_LIST_STEP:
      return { ...state, listSteps: action.payload };
    case ACTIONS.SET_SLOTS:
      return { ...state, slots: action.payload };
    case ACTIONS.SET_SELECTED_SLOT:
      return { ...state, selectedSlot: action.payload };
    case ACTIONS.SET_SELECTED_WAIT_FOR_VEHICLE:
      return { ...state, selectedWaitForVehicle: action.payload };
    case ACTIONS.SET_FINAL_STEP_COMPLETED:
      return { ...state, isFinalStepCompleted: action.payload };
    case ACTIONS.SET_MAKE_APPOINTMENT_COMPLETED:
      return { ...state, isMakeAppointmentCompleted: action.payload };
    case ACTIONS.SET_NOTIFICATION:
      return { ...state, notification: action.payload };
    case ACTIONS.INITIALIZE_LIST_STEPS:
      return { ...state, listSteps: defaultSteps };
    case ACTIONS.INITIALIZE_STEPS:
      return { ...initialState, listSteps: defaultSteps };
    case ACTIONS.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

// Create the provider for the service appointment context
export const ServiceAppointmentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(serviceAppointmentReducer, initialState);

  return (
    <ServiceAppointmentContext.Provider value={{ state, dispatch }}>{children}</ServiceAppointmentContext.Provider>
  );
};

// Create a custom hook to use the service appointment context
export const useServiceAppointment = () => useContext(ServiceAppointmentContext);
